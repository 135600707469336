import ConsultButton from "@/components/ConsultButton";
import { Box, Stack } from "@chakra-ui/react";
import {
  getCdnImageSrc,
  Image,
  VStack,
  responsive,
  colors,
  NextLink,
} from "goi_common";
import { ReactNode } from "react";

export default function BenefitThirdSection() {
  return (
    <VStack width="100%" alignItems="center" background={colors.gray900}>
      <VStack
        maxWidth={`${1200}px`}
        gap="28px"
        css={responsive({
          padding: {
            base: "64px 24px",
            lg: "120px 24px",
          },
        })}
      >
        <VStack width="100%" alignItems="center" gap="16px">
          <Box
            className="gray400 subtitle_21_b lg_head_28_b"
            id="home-flower"
            scrollMarginTop={100}
          >
            근조화환/근조기
          </Box>
          <Box className="white head_28_b lg_head_42_b" textAlign="center">
            {" "}
            전국 최저가 근조화환 발주
            <br />
            모바일로 간편하고 신속하게
          </Box>
          <Box
            className="gray300 subtitle_16_m lg_head_24_m"
            textAlign="center"
          >
            모바일로 간단하게 발주하고, <br className="lg_over_hidden" />
            월 결제로 정산은 더욱 쉽게
            <br />
            6만원부터 발주 가능한 전국 최저가 근조화환
          </Box>

          <VStack css={{ marginBottom: "20px" }}>
            <ConsultButton text="1달 체험하기" />

            <NextLink
              href="/flower/"
              css={responsive({
                background: colors.gray800,
                color: colors.white,
                padding: { base: "10px 38px", lg: "14px 48px" },
                borderRadius: "10px",
                fontSize: "21px",
                fontWeight: "bold",
              })}
            >
              자세히 알아보기
            </NextLink>
          </VStack>
        </VStack>
        <BenefitItem
          num={1}
          title={"전국최저가 근조화환 6만원+"}
          description={
            <>재사용 없는 근조화환 전국 최저가로 합리적으로 발주하세요</>
          }
          imageSize={{
            width: { base: "100%", lg: "55%" },
            height: { base: "100%", lg: "300px" },
          }}
        />
        <BenefitItem
          num={2}
          title={"모바일로 간편하게 근조화환 주문"}
          description={
            <>
              화환 발주, 현황, 도착알림까지
              <br />
              간편하게 신청하고 확인할 수 있도록 제공
            </>
          }
          imageSize={{
            width: { base: "100%", lg: "50%" },
            height: { base: "100%", lg: "300px" },
          }}
        />
      </VStack>
    </VStack>
  );
}

function BenefitItem({
  num,
  title,
  imageSize,
  description,
}: {
  num: number;
  title: string;
  imageSize: {
    width: { base: string; lg: string };
    height: { base: string; lg: string };
  };
  description: ReactNode;
}) {
  return (
    <Stack
      gap="16px"
      justifyContent="space-between"
      css={responsive({
        flexDirection: { base: "column", lg: "row" },
        padding: { base: "24px", lg: "52px 48px" },
        borderRadius: "16px",
      })}
      background={colors.gray100}
      overflow="hidden"
    >
      <VStack
        justifyContent="center"
        css={responsive({
          width: { base: "100%", lg: "50%" },
        })}
      >
        <Box className="orange650 subtitle_18_b lg_head_24_b">0{num}</Box>
        <Box
          className="gray900 head_24_b lg_head_32_b"
          css={responsive({
            whiteSpace: "pre-line",
            padding: { base: "8px 0 10px", lg: "16px 0 20px" },
          })}
        >
          {title}
        </Box>

        <Box
          className="gray600 body_14_r lg_subtitle_21_r"
          css={{ wordBreak: "keep-all" }}
        >
          {description}
        </Box>
      </VStack>
      <VStack borderRadius="16px" css={responsive(imageSize)}>
        <Image
          src={getCdnImageSrc(`b2b/home/flower-${num}.png`)}
          width="100%"
          height="auto"
          alt=""
        />
      </VStack>
    </Stack>
  );
}
