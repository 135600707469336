import { Box } from "@chakra-ui/react";
import { useOverlay } from "@toss/use-overlay";
import {
  ColorIcon,
  colors,
  customEvent,
  HStack,
  OverlayModal,
  pixelCustomEvent,
  PixelEventName,
  useResponsive,
  VStack,
} from "goi_common";
import React from "react";

export function useRecathModal() {
  const overlay = useOverlay();
  const { isBiggerThanMd } = useResponsive();
  const openModal = () => {
    pixelCustomEvent(PixelEventName.ViewContent);

    customEvent({
      newGtm: { action: "consult-button-click" },
    });

    overlay.open(({ isOpen, close: onClose }) => {
      return (
        <OverlayModal
          onClose={onClose}
          isOpen={isOpen}
          closeOnOverlayClick
          modalContentStyle={{
            minWidth: isBiggerThanMd ? "500px" : "100%",
            minHeight: isBiggerThanMd ? "900px" : "100%",
            height: isBiggerThanMd ? "900px" : "100%",
            borderRadius: isBiggerThanMd ? "10px" : "0",
            overflow: "hidden",
          }}
          overlayModalContent={
            <RecatchModal
              iframeSrc="https://goifuneral.recatch.cc/workflows/wlffgxnkiy"
              onClose={onClose}
            />
          }
        />
      );
    });
  };

  const openServiceDownloadModal = () => {
    pixelCustomEvent(PixelEventName.ViewContent);

    customEvent({
      newGtm: { action: "consult-button-click" },
    });

    overlay.open(({ isOpen, close: onClose }) => {
      return (
        <OverlayModal
          onClose={onClose}
          isOpen={isOpen}
          closeOnOverlayClick
          modalContentStyle={{
            minWidth: isBiggerThanMd ? "500px" : "100%",
            minHeight: isBiggerThanMd ? "600px" : "100%",
            height: isBiggerThanMd ? "600px" : "100%",
            borderRadius: isBiggerThanMd ? "10px" : "0",
            overflow: "hidden",
          }}
          overlayModalContent={
            <RecatchModal
              title="서비스 소개서 받아보기"
              iframeSrc="https://goifuneral.recatch.cc/workflows/jxlbbbzhof"
              onClose={onClose}
            />
          }
        />
      );
    });
  };

  return { openModal, openServiceDownloadModal };
}

const RecatchModal = ({
  onClose,
  title,
  iframeSrc,
}: {
  onClose: () => void;
  title?: string;
  iframeSrc: string;
}) => {
  return (
    <VStack width="100%" height="100%" background="white">
      <HStack justifyContent="space-between" alignItems="center" padding="20px">
        <Box className="subtitle_21_b">{title || "고이 비즈 도입 문의"}</Box>
        <Box onClick={onClose}>
          <ColorIcon name="icon-x-mono" color={colors.gray500} size={24} />
        </Box>
      </HStack>
      <iframe
        src={iframeSrc}
        style={{ width: "100%", height: "100%", border: "none" }}
      ></iframe>
    </VStack>
  );
};
