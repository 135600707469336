import { Spacing, VStack } from "goi_common";

import Header from "./Header";

interface LayoutProps {
  children: React.ReactNode;
  showConditional?: boolean;
}

export default function Layout({ children, showConditional }: LayoutProps) {
  return (
    <>
      <Header />
      <Spacing gap={66} />

      <VStack>{children}</VStack>
    </>
  );
}
