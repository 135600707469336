import ConsultButton from "@/components/ConsultButton";
import { Box, Stack } from "@chakra-ui/react";
import { getCdnImageSrc, Image, VStack, responsive, colors } from "goi_common";
import { ReactNode } from "react";

export default function BenefitFourthSection() {
  return (
    <VStack width="100%" alignItems="center" background={colors.black}>
      <VStack
        maxWidth={`${1200}px`}
        gap="28px"
        css={responsive({
          padding: {
            base: "64px 24px",
            lg: "120px 24px",
          },
        })}
      >
        <VStack width="100%" alignItems="center" gap="16px">
          <Box className="gray400 subtitle_21_b lg_head_28_b">접수/행정</Box>
          <Box className="white head_28_b lg_head_42_b" textAlign="center">
            {" "}
            신청 접수부터 <br className="lg_over_hidden" />
            행정 작업까지 간편하게
          </Box>
          <Box
            className="gray300 subtitle_16_m lg_head_24_m"
            textAlign="center"
          >
            조사복지의 시작과 끝을 모두 간편하게 시작하세요.
            <br />
            24시간 직원분들을 위해 대기하고 있어요!
          </Box>

          <ConsultButton />
        </VStack>
        <BenefitItem
          num={1}
          title={"전국최저가 근조화환 6만원+"}
          description={
            <>재사용 없는 근조화환 전국 최저가로 합리적으로 발주하세요</>
          }
        />
      </VStack>
    </VStack>
  );
}

function BenefitItem({
  num,
  title,

  description,
}: {
  num: number;
  title: string;

  description: ReactNode;
}) {
  return (
    <Stack
      gap="16px"
      justifyContent="space-between"
      css={responsive({
        flexDirection: { base: "column", lg: "row" },
        alignItems: { base: "center", lg: "flex-end" },
      })}
    >
      <VStack
        justifyContent="center"
        background={colors.gray100}
        position="relative"
        css={responsive({
          padding: { base: "24px 24px 0", lg: "52px 48px 0" },
          borderRadius: "16px",
          width: { base: "100%", lg: "50%" },
          height: { base: "100%", lg: "500px" },
        })}
        overflow="hidden"
      >
        <Box
          className="gray900 head_24_b lg_head_32_b"
          css={responsive({
            whiteSpace: "pre-line",
            padding: { base: "8px 0 10px", lg: "16px 0 20px" },
          })}
        >
          24시간, 연중무휴
          <br />
          조사지원 서비스
        </Box>

        <Box
          className="gray600 body_14_r lg_subtitle_21_r"
          css={{ wordBreak: "keep-all" }}
        >
          누적 10만건의 장례상담 노하우로,
          <br />
          24시간 장례 준비 걱정을 덜어드립니다.
        </Box>
        <Image
          src={getCdnImageSrc(`b2b/home/admin-1.png`)}
          css={responsive({
            width: { base: "100%", lg: "80%" },
            transform: {
              base: "translateY(40px)",
              lg: "translate(150px, 40px)",
            },
          })}
          height="auto"
          alt=""
        />
      </VStack>
      <VStack
        borderRadius="16px"
        background={colors.gray100}
        position="relative"
        css={responsive({
          padding: { base: "24px 24px 0", lg: "52px 48px 0" },
          borderRadius: "16px",
          width: { base: "100%", lg: "50%" },
          height: { base: "100%", lg: "500px" },
        })}
        overflow="hidden"
      >
        <Box
          className="gray900 head_24_b lg_head_32_b"
          css={responsive({
            whiteSpace: "pre-line",
            padding: { base: "8px 0 10px", lg: "16px 0 20px" },
          })}
        >
          실시간으로 아카이빙되는
          <br />
          장례서비스 지원 현황
        </Box>
        <Box
          className="gray600 body_14_r lg_subtitle_21_r"
          css={{ wordBreak: "keep-all" }}
        >
          장례접수,예산집행,행정서류까지
          <br />
          효율적이고 간편하게 관리하세요.
          <br />
          담당자분들의 퇴근을 앞당깁니다.
        </Box>
        <Image
          src={getCdnImageSrc(`b2b/home/admin-2.png`)}
          css={responsive({
            width: { base: "100%", lg: "80%" },
            transform: {
              base: "translateY(40px)",
              lg: "translate(150px, 40px)",
            },
          })}
          height="auto"
          alt=""
        />
      </VStack>
    </Stack>
  );
}
