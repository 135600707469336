import { OverlayProvider } from "@toss/use-overlay";
import { ThemeProvider } from "@emotion/react";
import type { AppProps } from "next/app";
import { RecoilRoot } from "recoil";
import "@/styles/reset.css";
import "@/styles/theme/font.css";

import { ChakraProvider } from "@chakra-ui/react";
import { getUserId, HackleProvider } from "@hackler/react-sdk";

import AOS from "aos";
import "aos/dist/aos.css";
import {
  chakraTheme,
  getUserProperties,
  initUserProperties,
  theme,
  HackleClient,
} from "goi_common";

import { ReactElement, ReactNode, useEffect } from "react";
import { NextPage } from "next";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";

import "slick-carousel/slick/slick.css";
import Layout from "@/components/Layout";
import Head from "next/head";

import { GlobalStyle } from "@/styles/GlobalStyle";
import { ReactQueryDevtools } from "react-query/devtools";
import Script from "next/script";

declare global {
  interface Window {
    kakao: any;
    naver: any;
    hackleClinet: any;
    eziok_std_process: (url: string, type: string, callback: string) => void;
    TossCert: any;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const handleResize = () => {
    const vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (HackleClient) {
      initUserProperties();
      const id = getUserId();

      const user = {
        userId: id,
        properties: getUserProperties(),
      };

      HackleClient.setUser(user);
    }
  }, []);

  const facebookPixelScript = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '574472278537796');
    fbq('track', 'PageView');
  `;

  const Components = (
    <HackleProvider hackleClient={HackleClient} supportSSR>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ChakraProvider resetCSS={true} theme={chakraTheme}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <RecoilRoot>
                <OverlayProvider>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </OverlayProvider>
              </RecoilRoot>
              <ReactQueryDevtools />
            </Hydrate>
          </QueryClientProvider>
        </ChakraProvider>
      </ThemeProvider>
    </HackleProvider>
  );

  const recatchScript = `
  (function (r, e, c, a, t, ch) {
    var h=r.getElementsByTagName(e)[0],i=r.createElement(c);
    i.async=true;
    i.defer=true;
    i.id='recatch-embed-script';
    i.src='https://cdn.recatch.cc/recatch-embed.iife.js?t='+a[0]+'&b='+a[1]+'&c='+t+'&tr=true&th='+ch+'&mode=sdk';
    h.prepend(i);
  })(document,'head','script',['goifuneral','wlffgxnkiy'],'recatch-form','light');
`;

  const recatchServiceDownloadScript = `
   (function (r, e, c, a, t, ch) {
    var h=r.getElementsByTagName(e)[0],i=r.createElement(c);
    i.async=true;
    i.defer=true;
    i.id='recatch-embed-script';
    i.src='https://cdn.recatch.cc/recatch-embed.iife.js?t='+a[0]+'&b='+a[1]+'&c='+t+'&tr=true&th='+ch+'&mode=sdk';
    h.prepend(i);
  })(document,'head','script',['goifuneral','jxlbbbzhof'],'recatch-form','light');
  `;

  return (
    <>
      <Head>
        <title>
          {pageProps.pageOgTitle ? pageProps.pageOgTitle : "고이 비즈"}
        </title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      {process.env.NEXT_PUBLIC_IS_PRODUCTION === "true" && (
        <Script
          id="fb-pixel"
          dangerouslySetInnerHTML={{ __html: facebookPixelScript }}
        />
      )}
      <Script
        dangerouslySetInnerHTML={{ __html: recatchScript }}
        id="recatch-embed-script"
      />
      <Script
        dangerouslySetInnerHTML={{ __html: recatchServiceDownloadScript }}
        id="recatch-embed-service-download-script"
      />

      {Components}
    </>
  );
}

export default MyApp;
