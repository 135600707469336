import { useRecathModal } from "@/hooks/useRecathModal";
import { Box } from "@chakra-ui/react";
import { Button, colors, getCdnImageSrc, responsive, VStack } from "goi_common";
import React from "react";

export default function HeroSection() {
  const { openModal } = useRecathModal();

  return (
    <VStack
      width="100%"
      css={responsive({
        height: { base: "530px", lg: "618px" },
        padding: { base: "0 30px", lg: "0" },
      })}
      background={`url(${getCdnImageSrc("b2b/herobackground.png")})`}
      backgroundSize="cover"
      backgroundPosition="50% 50%"
      position="relative"
      alignItems="center"
    >
      <VStack
        maxWidth={`${1200}px`}
        justifyContent="center"
        height="100%"
        width="100%"
      >
        <Box
          className="white"
          css={responsive({
            fontSize: { base: 32, lg: 56 },
            fontWeight: { base: 700 },
            marginBottom: { base: 20, lg: 60 },
          })}
          zIndex={3}
        >
          상조용품부터
          <br className="lg_over_hidden" /> 장례서비스 제휴까지
          <br />
          기업 조사지원의 모든 것
        </Box>

        <Button
          css={responsive({
            backgroundColor: colors.orange650,
            color: "white",
            padding: { base: "16px 28px", lg: "21px 52px" },
            fontSize: { base: 21, lg: 32 },
            fontWeight: { base: 700, lg: 700 },
            width: "fit-content",
            borderRadius: { base: "8px", lg: "10px" },
          })}
          onClick={openModal}
        >
          제휴 알아보기
        </Button>
      </VStack>
    </VStack>
  );
}
