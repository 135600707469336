import ConsultButton from "@/components/ConsultButton";
import { Box, Stack } from "@chakra-ui/react";
import { getCdnImageSrc, Image, VStack, responsive, colors } from "goi_common";
import { ReactNode } from "react";

export default function BenefitFirstSection() {
  return (
    <VStack width="100%" alignItems="center" background={colors.gray900}>
      <VStack
        maxWidth={`${1200}px`}
        gap="28px"
        css={responsive({
          padding: {
            base: "64px 24px",
            lg: "120px 24px",
          },
        })}
      >
        <VStack width="100%" alignItems="center" gap="16px">
          <Box
            className="gray400 subtitle_21_b lg_head_28_b"
            id="home-funeral"
            scrollMarginTop={100}
          >
            임직원 장례 서비스
          </Box>
          <Box className="white head_28_b lg_head_42_b">
            기업 부담은 <b className="orange650">절반</b>, 혜택은{" "}
            <b className="orange650">2배</b>
          </Box>
          <Box
            className="gray300 subtitle_16_m lg_head_24_m"
            textAlign="center"
          >
            기업 복지비용은 비싸고, <br className="lg_over_hidden" />
            임직원 만족도는 낮은 서비스로 고민이신가요?
            <br />
            지금 바로 회사와 직원 모두 만족하는{" "}
            <br className="lg_over_hidden" />
            조사지원서비스 도입 상담을 받아보세요.
          </Box>

          <ConsultButton text="견적 받아보기" />
        </VStack>
        <BenefitItem
          num={1}
          title={"기업이 부담한 금액의 \n2배 혜택 제공  "}
          description={
            <>
              <b>
                50만원 기업이 부담하면, <br />
                100만원의 장례 서비스 혜택을 드려요
              </b>
              <br /> 시중 상조서비스의 1/3 가격으로 조사복지를 제공하세요.
            </>
          }
          imageSize={{
            width: { base: "100%", lg: "50%" },
            height: { base: "100%", lg: "300px" },
          }}
        />
        <BenefitItem
          num={2}
          title={"가입비, 월납입금 없이 시작하는\n상조 서비스"}
          description={
            <>
              그 동안 기업이 많은 비용을 지출해야만 했던
              <br />
              직원 장례서비스는 그만, 고이가 함께 부담해요.
            </>
          }
          imageSize={{
            width: { base: "100%", lg: "40%" },
            height: { base: "100%", lg: "300px" },
          }}
        />
        <BenefitItem
          num={3}
          title={"임직원 상조 첫 지원시\n기업 부담금 0원"}
          description={
            <>
              도입 및 변경에 비용이 많이 든다고 고민하지 마세요
              <br />
              이용해보는 것만큼 확실한건 없어요.
            </>
          }
          imageSize={{
            width: { base: "90%", lg: "34%" },
            height: { base: "100%", lg: "300px" },
          }}
        />
      </VStack>
    </VStack>
  );
}

function BenefitItem({
  num,
  title,
  imageSize,
  description,
}: {
  num: number;
  title: string;
  imageSize: {
    width: { base: string; lg: string };
    height: { base: string; lg: string };
  };
  description: ReactNode;
}) {
  return (
    <Stack
      gap="16px"
      justifyContent="space-between"
      css={responsive({
        flexDirection: { base: "column", lg: "row" },
        padding: { base: "24px", lg: "52px 48px" },
        borderRadius: "16px",
      })}
      background={colors.gray100}
    >
      <VStack
        justifyContent="center"
        css={responsive({
          width: { base: "100%", lg: "50%" },
        })}
      >
        <Box className="orange650 subtitle_18_b lg_head_24_b">0{num}</Box>
        <Box
          className="gray900 head_24_b lg_head_32_b"
          css={responsive({
            whiteSpace: "pre-line",
            padding: { base: "8px 0 10px", lg: "16px 0 20px" },
          })}
        >
          {title}
        </Box>

        <Box
          className="gray600 body_14_r lg_subtitle_21_r"
          css={{ wordBreak: "keep-all" }}
        >
          {description}
        </Box>
      </VStack>
      <VStack borderRadius="16px" css={responsive(imageSize)}>
        <Image
          src={getCdnImageSrc(`b2b/home/funeral-${num}.png`)}
          width="100%"
          height="auto"
          alt=""
        />
      </VStack>
    </Stack>
  );
}
