import B2BReviewSection from "@/containers/Home/B2BReviewSection";
import BenefitFirstSection from "@/containers/Home/BenefitFirstSection";
import BenefitFourthSection from "@/containers/Home/BenefitFourthSection";
import BenefitSecondSection from "@/containers/Home/BenefitSecondSection";
import BenefitThirdSection from "@/containers/Home/BenefitThirdSection";
import B2BCollaborationSection from "@/containers/Home/B2BCollaborationSection";
import HeroSection from "@/containers/Home/HeroSection";
import ReviewSection from "@/containers/Home/ReviewSection";

import {
  customEvent,
  pixelCustomEvent,
  PixelEventName,
  VStack,
} from "goi_common";
import TrustSection from "@/containers/Home/TrustSection";
import CollaborationSection from "@/containers/Home/CollaborationSection";
import PRSection from "@/containers/Home/PRSection";
import Footer from "@/containers/Home/Footer";
import B2BServiceDownloadSection from "@/containers/Home/B2BServiceDownloadSection";
import { useEffect } from "react";

export default function Index() {
  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e.data.type === "openRecatchModal") {
        pixelCustomEvent(PixelEventName.CompleteRegistration);
        customEvent({
          newGtm: { action: "b2b-submit" },
        });
      }
      if (e.data.type === "bookingComplete") {
        window.location.href = "https://partners.goifuneral.co.kr/submit/"; // 샘플 URL 대신 보낼 주소 입력
      }
      if (e.data.type === "disqualified") {
        window.location.href = "https://partners.goifuneral.co.kr/submit/"; // 샘플 URL 대신 보낼 주소 입력
      }
      if (e.data.type === "closeModal") {
        window.location.href = "https://partners.goifuneral.co.kr/submit/"; // 샘플 URL 대신 보낼 주소 입력
      }
    });
  }, []);

  return (
    <VStack width="100%">
      <HeroSection />
      <B2BCollaborationSection />
      <B2BReviewSection />
      <ReviewSection />
      <B2BServiceDownloadSection />
      <BenefitFirstSection />
      <BenefitSecondSection />
      <BenefitThirdSection />
      <BenefitFourthSection />
      <TrustSection />
      <CollaborationSection />
      <PRSection />
      <Footer />
    </VStack>
  );
}
