import { Box, Grid } from "@chakra-ui/react";
import { colors, getCdnImageSrc, Image, responsive, VStack } from "goi_common";

export default function B2BCollaborationSection() {
  return (
    <VStack
      width="100%"
      alignItems="center"
      background={colors.gray100}
      css={responsive({
        padding: { base: "64px 24px 72px", lg: "99px 0 88px" },
      })}
    >
      <VStack maxWidth={`${1200}px`} width="100%" gap="60px">
        <Box className="gray900 head_24_b lg_head_42_b" textAlign="center">
          임직원 20,000명+이 <br />
          고이BIZ를 선택했습니다
        </Box>
        <VStack
          width="100%"
          alignItems="center"
          justifyContent="center"
          gap="10px"
        >
          <Grid
            width="100%"
            css={responsive({
              gridRowGap: { base: "10px", lg: "20px" },
              gridColumnGap: { base: "4px", lg: "20px" },
              gridTemplateColumns: {
                base: "2fr 2fr 2fr",
                lg: "1fr 1fr 1fr 1fr 1fr 1fr",
              },
            })}
          >
            <GridItem id={1} text="중소벤처기업부" />
            <GridItem id={2} text="교보생명" />
            <GridItem id={3} text="국민은행" />
            <GridItem id={4} text="신용보증기금" />
            <GridItem id={5} text="카카오벤처스" />
            <GridItem id={6} text="서울대학교" />
          </Grid>
          <Grid
            width="100%"
            css={responsive({
              alignItems: "center",
              gridRowGap: { base: "16px", lg: "20px" },
              gridColumnGap: { base: "4px", lg: "20px" },
              gridTemplateColumns: {
                base: "2fr 2fr 2fr",
                lg: "1fr 1fr 1fr 1fr 1fr 1fr",
              },
            })}
          >
            <GridItem id={0} text="" className="lg_under_hidden" />
            <GridItem id={7} text="서울지방변호사회" />
            <GridItem id={8} text="한국소비자원" />
            <GridItem id={9} text="서울시농수산공사" />
            <GridItem
              id={10}
              text="서울의료원노동조합"
              className="lg_under_hidden"
            />
          </Grid>
          <Grid
            width="100%"
            css={responsive({
              gridRowGap: { base: "10px", lg: "20px" },
              gridColumnGap: { base: "4px", lg: "20px" },
              gridTemplateColumns: {
                base: "2fr 2fr 2fr",
                lg: "1fr 1fr 1fr 1fr 1fr 1fr",
              },
            })}
          >
            <GridItem id={11} text="한국신용데이터" />
            <GridItem id={12} text="한국스타트업포럼" />
            <GridItem id={13} text="KB핀테크" />
            <GridItem id={14} text="헬로유니콘" />
            <GridItem id={15} text="온더룩" />
            <GridItem id={16} text="비즈니스캔버스" />
          </Grid>
        </VStack>
      </VStack>
    </VStack>
  );
}

function GridItem({
  id,
  text,
  className,
}: {
  id: number;
  text: string;
  className?: string;
}) {
  return (
    <VStack alignItems="center" gap="8px" width="100%" className={className}>
      <Box
        overflow="hidden"
        css={responsive({
          width: { base: "80px", lg: "100%" },
        })}
      >
        <Image src={getCdnImageSrc(`b2b/collabo/${id}.png`)} alt="" />
      </Box>
      <Box
        fontWeight={500}
        className="gray600"
        css={responsive({
          fontSize: { base: "14px", lg: "20px" },
        })}
      >
        {text}
      </Box>
    </VStack>
  );
}
