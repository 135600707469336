import {
  VStack,
  HStack,
  useResponsive,
  Image,
  getCdnImageSrc,
  ColorIcon,
  colors,
  LargeButton,
  LARGEL_BUTTON_TYPE,
  NextLink,
  customEvent,
  responsive,
} from "goi_common";
import { Box } from "@chakra-ui/react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useState } from "react";
import SlideBase from "react-slick";
import dayjs from "dayjs";
import { useRouter } from "next/router";

export default function ReviewSection({
  district,
}: {
  district?: {
    title: string;
    eventPath: "home_honam" | "home_dggb" | "home_female";
  };
}) {
  const { isBiggerThanLg } = useResponsive();
  return (
    <VStack
      width="100%"
      alignItems="center"
      background={colors.warmGray100}
      css={responsive({
        paddingTop: { base: "64px", lg: "150px" },
        paddingBottom: { base: "64px", lg: "100px" },
      })}
    >
      <VStack
        maxWidth={`${1200}px`}
        width="100%"
        alignItems="center"
        css={responsive({
          padding: { base: "0 24px 16px", lg: "0 24px 64px" },
        })}
      >
        <Box className="gray900 head_24_eb lg_head_42_b" textAlign="center">
          {district?.title && `${district.title} `}장례 만족도{" "}
          <span className="orange650">4.9</span>
          점,
          <br />
          이미 많은 상주님께서 {isBiggerThanLg || <br />}만족하셨습니다.
        </Box>
        <Box
          className="gray600 subtitle_18_eb lg_head_28_m"
          textAlign="center"
          css={responsive({
            padding: { base: "16px 0 0", lg: "16px 0 40px" },
          })}
        >
          단 한 건의 거짓이나 과장 없이, {isBiggerThanLg || <br />}상주님께서
          직접 작성한 후기입니다.
        </Box>
        {isBiggerThanLg && (
          <NextLink
            href="https://www.goifuneral.co.kr/review/"
            css={{ width: "327px" }}
            event={{
              newGtm: {
                path: district?.eventPath || "home",
                trigger_and_target: "CTA-uv_review",
              },
            }}
          >
            <LargeButton
              className="subtitle_21_b"
              buttonType={LARGEL_BUTTON_TYPE.ORANGE}
              height={66}
            >
              더 많은 후기 보기
            </LargeButton>
          </NextLink>
        )}
      </VStack>
      <VStack width="100%" overflow="hidden">
        <ReviewCarousel />
      </VStack>
      {isBiggerThanLg || (
        <NextLink
          href="https://www.goifuneral.co.kr/review/"
          css={{ width: "100%", padding: "20px 24px 0" }}
          event={{
            newGtm: {
              path: district?.eventPath || "home",
              trigger_and_target: "CTA-uv_review",
            },
          }}
        >
          <LargeButton
            className="subtitle_21_b"
            buttonType={LARGEL_BUTTON_TYPE.ORANGE}
            height={66}
          >
            더 많은 후기 보기
          </LargeButton>
        </NextLink>
      )}
    </VStack>
  );
}

const REVIEWS = [
  {
    id: 212,
    headline:
      "처음 문의때부터 매우 상세한 설명과 진심어린 대화부터 감동적이었고, 장지선택시 함께 동행해주시면서 다양한 얘기를 들을 수 있었어요",
    content:
      "아버님 돌아가시기 전, 급하게 상조회사를 찾아보았고, 여러 선택지 중 고이를 선택하게 되었습니다. 처음 문의때부터 매우 상세한 설명과 진심어린 대화부터 감동적이었고, 장지선택시 함께 동행해주시면서 다양한 얘기를 들을 수 있었어요. 상을 치르는 동안에도 다른 부분은 신경쓰지 않도록 세심하게 처리해주셨고, 특히 입관 시 아버님을 잘 돌봐주셔서 평안하게 떠나시는 모습을 볼 수 있었습니다. 가격 역시 제가 쓰지 않은 비용은 차감해서 합리적으로 장례식을 치를 수 있었습니다. 저는 이후로도 고이를 이용할 것 같고, 주변에도 강력하게 추천할 수 있을 것 같습니다. 최서규 장례지도사님 너무 감사드리고, 덕분에 잘 보내드릴 수 있었습니다. 감사드립니다.",
    created_at: "2024-02-23T16:28:15.371273+09:00",
    funeral_director: { id: 0, name: "최서규" },
    user: {
      age_and_gender: "38세 남성",
      nickname: "김OO",
      picture:
        "http://k.kakaocdn.net/dn/bH7SnW/btsv7tSEtWk/ULklwuwp81rgGfexysHQ0k/img_640x640.jpg",
    },
    score: 5,
  },
  {
    id: 368,
    headline:
      "고이에서 배정해주신 지도사님이 정말 친절하시고 꼼꼼하시더라고요. 감사한 마음을 많이 느꼈습니다.",
    content: `
    급하게 아버지를 모셔야하는 상황이 되어서, 평소 관심있던 서비스인 고이를 이용하게 되었습니다. 장례식 문화나 절차같은 것들에 대해 잘 모르던 사람이기에 장례식 준비가 막막했으나 아버지 임종 하루전부터 고이 측 상담사분과 연락하며 준비를 잘 할 수 있어, 아버지를 잘 모실 수 있었습니다.
    일단 아버지 임종을 맞이했을 때 상주로서 진행해야하는 것들을 과장님이 잘 알려주셨고, 임종 이후 장례 지도사님께서 3일 내내 많은 도움을 주셨습니다. 고이에서 배정해주신 지도사님이 정말 친절하시고 꼼꼼하시더라고요. 감사한 마음을 많이 느꼈습니다.
    젊은 나이에 장례식에 대해 아무것도 모르는 상태로 장례를 치루게 된 상황에서도 고이 서비스 덕분에 장례식을 찾는 모든 손님들에게 참 아버지를 잘 보내드렸다는 이야기를 많이 들었고, 심적으로 위안이 많이 되었습니다.
    강력 추천하는 서비스입니다.`,
    created_at: "2024-05-13T10:42:15.371273+09:00",
    funeral_director: { id: 4, name: "이명신" },
    user: {
      age_and_gender: "30세 남성",
      nickname: "김OO",
    },
    score: 5,
  },
  {
    id: 166,
    headline:
      "장례지도사께선 연결이 되자마자 추석 다음날 새벽 1시 25분 부터 필요한 조처를 빠르게 진행해주셨습니다",
    content: `추석 명절 연휴에 상을 당해 당황스럽고 경황이 없었는데 초기 전화상담부터 친절하게 세세히 안내해주셔서 신뢰가 갔습니다.
    특히 최서규장례지도사께선 연결이 되자마자 추석 다음날 새벽 1시 25분 부터 필요한 조처를 빠르게 진행해주셨습니다. 매순간 섬세하고 따뜻하게 배려해주시고 장례절차에 대한 적합한 설명과 화장장을 비롯하여 장례식장, 추모공원등과의 원활한 소통을 도와주셨습니다. 또 바르고 투명한 장례문화 조성을 위해 애써주시는 점도 무척 감명깊었습니다. 최서규본부장님께서 계셔서 너무 든든했습니다. 덕분에 차부하고 마음 편히 아버님 잘 모실 수 있었습니다. 다시 한번 깊이깊이 감사드립니다.`,
    created_at: "2023-10-04T08:13:15.371273+09:00",
    funeral_director: { id: 0, name: "최서규" },
    user: {
      age_and_gender: "54세 여성",
      nickname: "변OO",
    },
    score: 5,
  },
  {
    id: 172,
    headline:
      "고이상조 덕분에 저희 가족과 조문객 모두 불편함 없이 대단히 만족스럽게 장례를 잘 치렀습니다.",
    content: `
    얼마 전 친모상을 치렀습니다. 상주 역할은 처음이다보니 뭐부터 어떻게 해야할지 막막했습니다. 고이상조 덕분에 저희 가족과 조문객 모두 불편함 없이 대단히 만족스럽게 장례를 잘 치렀습니다.    
    제가 고이 상조를 선택하게 된 이유는 1) 합리적인 가격 구조 2) 상주의 마음과 입장을 고려한 친절한 상담 3) 장례지도사의 경력이었습니다. 이미 잘 알려진 보ㅇ상조와 프ㅇㅇ 비교했을 때 3번은 확인하기 어렵겠지만, 1번과 2번은 확실히 고이 상조가 비교우위에 있습니다.
    실제 장례를 치러보니 상조회사를 잘 고른것 같네요. 깐깐하신 아버지까지 매우 흡족해 하셨습니다. 특히 장례자도사님이 기억에 많이 남습니다. 누구나 같은 일을 계속 하다보면 무뎌지기 쉽상인데, 자기 일 처럼 상주의 입장에서 도와주신 점 감사했습니다. 마치 장례를 여러번 치러본 상주가 한 명 더 있는 것 같았습니다.
    접객 도우미 분들 팀웍도 좋고 친절하고 일도 잘해주십니다. 접객 도우미 한 분이 근조 화환에 있는 국화, 꽃잎을 가지고 제단의 성경책 주변을 꾸며주셨는데 너무 이뻤고, 위로가 되었습니다. 입관할때도 마음의 불편함 없이 어머니 마지막 모습 잘 간직할 수 있었습니다. 관 꽃 장식도 훌륭합니다.
    장례 상담이나 진행 중에 물품 구매 유도나 상품 업그레이드, 팁 요구 전혀 없고 오로지 장례에만 집중할 수 있었습니다. 어머니와의 마지막을 부족함 없이, 후회 없이 보낼 수 있었습니다. 고이 상조 감사합니다.`,
    created_at: "2023-10-17T17:22:15.371273+09:00",
    funeral_director: { id: 0, name: "최서규" },
    user: {
      age_and_gender: "40세 남성",
      nickname: "전OO",
    },
    score: 5,
  },
  {
    id: 268,
    headline:
      "너무도 친절하게 하나부터 열까지 세심하게 알려주시고 도와주셔서 무탈하게 마무리 되었습니다.",
    content: `갑작스러운 아빠의 장례에 정성스럽게 상담해 주셨고 특히 저희가 서울 경기도에 거주하고 있었기에 대구에 계신 엄마가 혼자 경황이 없을 수 있었는데 착착 발빠르게 진행해 주셔서 너무 고마웠습니다.
    진짜 장례지도사님이 아니었다면 아마 엉망 진창이 되었을 겁니다. 너무도 친절하게 하나부터 열까지 세심하게 알려주시고 도와주셔서 무탈하게 마무리 되었습니다.
    고마운 마음에 별도로 준비한 사례금을 드리려 했는데 한사코 규정에 어긋난다며 사양하시며 대신 주변에 홍보 많이 해 달라고 하시더라고요
    다시한번 진심으로 감사합니다!`,
    created_at: "2024-02-02T17:22:15.371273+09:00",
    funeral_director: { id: 7, name: "강성환" },
    user: {
      age_and_gender: "54세 여성",
      nickname: "최OO",
    },
    score: 5,
  },
];

const initialSlide = 0;

export function ReviewCarousel() {
  const [beforeCount, setBeforeCount] = useState<number>(initialSlide);

  return (
    <VStack gap={60} position="relative" overflow="hidden">
      <Slide
        initialSlide={initialSlide}
        beforeChange={(_, next) => setBeforeCount(next)}
        arrows={false}
        centerMode={true}
        infinite={true}
        variableWidth={true}
      >
        {REVIEWS?.map((r: any, index: number) => (
          <NewReviewCard {...r} key={r.id} isCurrent={index === beforeCount} />
        ))}
      </Slide>
    </VStack>
  );
}

const Slide = styled(SlideBase)`
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    padding: 0 8px;
  }
  .slick-list {
    margin: 0 -8px;
  }
`;

export function NewReviewCard({
  id,
  headline,
  content,
  created_at,
  funeral_director,
  user,
  score,
  isCurrent,
}: any) {
  const router = useRouter();
  return (
    <VStack
      gap={14}
      css={responsive({
        height: "500px",
        backgroundColor: "white",
        borderRadius: "20px",
        position: "relative",
        opacity: isCurrent ? "1" : "40%",
        boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.08)",
        margin: "16px 0",
        width: { base: "290px", lg: "384px" },
      })}
    >
      <HStack
        justifyContent="end"
        alignItems="center"
        padding="22px 30px 0"
        style={{ color: colors.warmGray700 }}
        gap={4}
        className="body3_m"
        as="button"
        onClick={() => {
          customEvent({
            newGtm: {
              path: "home",
              trigger_and_target: "card-uv_review_detail",
            },
          });
          router.push(`https://www.goifuneral.co.kr/review/${id}/`);
        }}
      >
        더보기
        <ColorIcon
          name="icon-arrow-right-circle-mono"
          color={colors.warmGray700}
          size={16}
        />
      </HStack>

      <VStack gap={4} padding="0 32px">
        <div className="body3_m" style={{ color: colors.gray400 }}>
          {user.nickname} 님의 후기 |{" "}
          {dayjs(created_at).format("YYYY년 M월 D일")}
        </div>
        <HStack gap={6} className="subtitle1_sb" alignItems="center">
          <HStack gap={1}>
            {Array(score)
              .fill(0)
              .map((item, index) => (
                <ColorIcon
                  key={index}
                  name="rating_star"
                  color="#F18334"
                  size={22}
                />
              ))}
          </HStack>
          <Box className="gray900">5</Box>
        </HStack>
      </VStack>

      <VStack gap={8} padding="0 32px">
        {headline && <div className="subtitle2_sb black">{headline}</div>}
        <EllipsisDiv
          headlineLength={headline.length}
          className="body3_r gray700"
        >
          {content}
        </EllipsisDiv>
      </VStack>

      <div
        style={{
          position: "absolute",
          bottom: 0,
          height: "100px",
          width: "100%",
          background: colors.black,
          paddingTop: "40px",
          display: "flex",
          alignItems: "flex-end",
          borderRadius: "0 0 20px 20px",
        }}
      >
        <HStack width="100%" justifyContent="space-between">
          <VStack gap={3} padding="60px 10px 0 24px">
            <div style={{ color: colors.gray400 }}>담당 장례지도사</div>
            <div style={{ color: colors.white }} className="subtitle3">
              {funeral_director.name} 장례지도사
            </div>
          </VStack>

          <Box maxWidth="100%" overflowX="hidden" borderRadius="0 0 20px 0">
            <Image
              src={getCdnImageSrc(
                `images/funeraldirector/${funeral_director.id}/square.png`
              )}
              alt={funeral_director.name}
              width="140"
            />
          </Box>
        </HStack>
      </div>
    </VStack>
  );
}

const EllipsisDiv = styled.div<{ headlineLength: number }>`
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  ${({ headlineLength }) =>
    css`
      -webkit-line-clamp: ${9 - Math.floor(headlineLength / 13)};
    `}
`;
