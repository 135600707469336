import { Box, Stack } from "@chakra-ui/react";
import {
  colors,
  getCdnImageSrc,
  HStack,
  Image,
  responsive,
  VStack,
} from "goi_common";
import styled from "@emotion/styled";
import { useState } from "react";
import SlideBase from "react-slick";

export default function B2BReviewSection() {
  return (
    <VStack
      width="100%"
      alignItems="center"
      css={responsive({
        padding: { base: "64px 0px 72px", lg: "99px 0 88px" },
      })}
    >
      <VStack maxWidth={`${1100}px`} width="100%" gap="40px">
        <Box className="gray900 head_24_b lg_head_42_b" textAlign="center">
          고이와 함께한 기업들의 후기
        </Box>
        <Stack
          css={responsive({
            flexDirection: { base: "column", lg: "row" },
            gap: { base: "10px", lg: "0px" },
          })}
          justifyContent="space-between"
          padding="0 20px"
        >
          <VStack alignItems="center">
            <Box className="gray500 subtitle_16_m lg_subtitle_21_m">
              제휴사 임직원 수
            </Box>
            <HStack
              css={responsive({
                fontSize: { base: 32, lg: 58 },
                fontWeight: 700,
                color: colors.orange650,
              })}
              height="100%"
              alignItems="flex-start"
            >
              <Box>20,000명</Box>
              <Box lineHeight="100%">+</Box>
            </HStack>
          </VStack>
          <VStack alignItems="center">
            <Box
              className="gray500 subtitle_16_m lg_subtitle_21_m"
              css={responsive({ marginRight: { base: "0px", lg: "70px" } })}
            >
              상조서비스 만족도
            </Box>
            <HStack
              css={responsive({
                fontSize: { base: 32, lg: 58 },
                fontWeight: 700,
                color: colors.orange650,
                marginRight: { base: "0px", lg: "70px" },
              })}
              height="100%"
              alignItems="flex-start"
            >
              4.9/5점
            </HStack>
          </VStack>
          <VStack alignItems="center">
            <Box
              className="gray500 subtitle_16_m lg_subtitle_21_m"
              css={responsive({ marginRight: { base: "0px", lg: "40px" } })}
            >
              업계 최초 누적 투자
            </Box>
            <HStack
              css={responsive({
                fontSize: { base: 32, lg: 58 },
                fontWeight: 700,
                color: colors.orange650,
                marginRight: { base: "0px", lg: "40px" },
              })}
              height="100%"
              alignItems="flex-start"
            >
              <Box>47억</Box>
              <Box lineHeight="100%">+</Box>
            </HStack>
          </VStack>
        </Stack>

        <HStack className="lg_under_hidden">
          <VStack width="100%" alignItems="center" gap="20px">
            <B2BReviewCard
              num={1}
              companyName="서울지방변호사회"
              serviceDescription="변호사협회"
              name=""
              title="임직원이 가장 만족할 수 있는 복지를 주고자 도입했습니다."
              description="업무 강도가 높은만큼 만족도를 높일 수 있는 제도나 복리후생을 신경 쓰고 있었습니다. 경력직 비중이 꽤 높아서 평균 연령이 30대가 넘다 보니 경조사 제도도 알아보고 있었구요. 그러던 차에 고이에서 제안을 주었는데, 상주 편의 용품이나 장례지도사 매칭 같은 합리적인 부분이 되게 좋다고 느껴졌습니다. 장례식 한 번에 지출하는 비용이 클 수는 있지만 평생 한 번 쓸까 말까 한 제도이다 보니 임직원이 가장 만족할 수 있는 복지를 주고자 도입했습니다."
              height="594px"
            />
            <B2BReviewCard
              num={2}
              companyName="서울농수산식품공사"
              serviceDescription="농수산 관리 지방공기업"
              name=""
              title="결국 고이의 제휴 혜택이 더 커서 최종적으로 고이를 선택했습니다."
              description="조사 지원 체계를 생각해본 적 없었는데 복리후생 제안 메일을 계기로 여러 회사를 비교해 보았습니다. 1년에 몇 번 없는 일이다 보니 비용적 부담이 크지는 않지만 이왕이면 합리적인 비용으로 서비스를 제공하는 업체를 찾으려고 했고, 기업 상조로 유명한 회사 서비스랑 함께 비교했습니다. 결국 고이의 제휴 혜택이 더 커서 최종적으로 고이를 선택했습니다."
              height="560px"
            />
          </VStack>
          <VStack width="100%" alignItems="center" gap="20px">
            <B2BReviewCard
              num={3}
              companyName="카카오벤처스"
              serviceDescription="스타트업 전문 벤처캐피탈"
              name="관리이사 김정록"
              title="원래 이용하던 상조 복지를 바꿀만큼 매력적이었습니다."
              description="스타트업의 서비스는 대표와 많은 부분이 닮아 있다고 생각합니다. 고이 대표님의 창업 스토리와 비전을 잘 이해하고 있었기 때문에 고이가 처음 B2B 서비스를 제안했을 때 믿고 선택할 수 있었습니다. 지난 달 직원의 조부모상이 있어 고이를 이용했는데, 장례지도사님은 물론 이모님들까지 모두 친절하고 정성스럽게 장례를 맡아주고 계셔서 안심이 되었습니다. 장례 복지몰 만족도가 특히 높았던 것 같고, 장례식장, 장지 소개 같은 부분도 합리적인 비용으로 잘 진행해주었습니다. 원래 이용하던 상조 복지를 바꿀만큼 매력적인 서비스이니 고민하고 있는 대표님이 있다면 강력히 추천드립니다."
              height="652px"
            />
            <B2BReviewCard
              num={4}
              companyName="온더룩"
              serviceDescription="No.1 패션 검색 서비스"
              name="인사팀장 이태윤"
              title="고이를 통해 장례를 치른 임직원의 추천으로 도입하게 되었습니다."
              description="투자 유치 이후 팀원 수가 빠르게 늘어나면서 인사, 총무, 회계 같은 백오피스 업무가 급증했습니다. 경조사도 그 중에 하나였고, 합리적인 비용에 믿을만한 업체를 찾았습니다. 그런던 차에 한 팀원이 작년에 고이에서 장례를 치루었는데, 장례 준비 과정부터 장례가 끝난 다음까지도 너무 편하고 좋았다고 말해주었습니다. 덕분에 어머님을 잘 보낸 것 같다면서요."
              height="500px"
            />
          </VStack>
        </HStack>
        <HStack className="lg_over_hidden">
          <ReviewCarousel />
        </HStack>
      </VStack>
    </VStack>
  );
}

function B2BReviewCard({
  num,
  companyName,
  serviceDescription,
  name,
  title,
  description,
  height,
}: {
  num: number;
  companyName: string;
  serviceDescription: string;
  name: string;
  title: string;
  description: string;
  height?: string;
}) {
  return (
    <VStack
      css={responsive({
        boxShadow: "0px 0px 30px 0px rgba(119, 130, 142, 0.12)",
        borderRadius: "20px",
        padding: { base: "30px 30px", lg: "40px 48px" },
        width: { base: "330px", lg: "520px" },
        height: { base: "630px", lg: height || "auto" },
      })}
    >
      <HStack width="100%" alignItems="center" gap="10px" margin="0 0 16px">
        <VStack
          width="80px"
          height="80px"
          alignItems="center"
          justifyContent="center"
        >
          <Box width="60px" height="60px">
            <Image
              src={getCdnImageSrc(`b2b/collabo/review_${num}.png`)}
              alt=""
            />
          </Box>
        </VStack>
        <VStack margin="0 0 10px">
          <Box className="gray900 subtitle_18_b lg_head_24_b">
            {companyName}
          </Box>
          <Box className="gray500 subtitle_16_m lg_subtitle_21_m">
            {serviceDescription}
          </Box>
          <Box className="gray500 subtitle_16_m lg_subtitle_21_m">{name}</Box>
        </VStack>
      </HStack>
      <VStack gap="10px">
        <Box className="gray900 subtitle_21_b lg_head_28_b">{title}</Box>
        <Box
          className="gray600 subtitle_16_m lg_subtitle_21_m"
          css={{ lineHeight: "1.8" }}
        >
          {description}
        </Box>
      </VStack>
    </VStack>
  );
}

const initialSlide = 0;

function ReviewCarousel() {
  const [, setBeforeCount] = useState(initialSlide);

  return (
    <VStack
      gap={60}
      position="relative"
      overflow="hidden"
      padding="10px 0"
      width="100%"
    >
      <Slide
        initialSlide={initialSlide}
        beforeChange={(_, next) => setBeforeCount(next)}
        arrows={false}
        centerMode={true}
        infinite={true}
        variableWidth={true}
        autoplay={true}
        speed={1000}
      >
        <B2BReviewCard
          num={1}
          companyName="서울지방변호사회"
          serviceDescription="변호사협회"
          name=""
          title="임직원이 가장 만족할 수 있는 복지를 주고자 도입했습니다."
          description="업무 강도가 높은만큼 전직원 미슐랭 회식 등과 같이 업무 만족도를 높일 수 있는 제도나 복리후생을 신경 쓰고 있었습니다. 경력직 비중이 꽤 높아서 평균 연령이 30대가 넘다 보니 경조사 제도도 알아보고 있었구요. 그러던 차에 고이에서 제안을 주었는데, 상주 편의 용품이나 장례지도사 매칭 같은 합리적인 부분이 되게 좋다고 느껴졌습니다. 장례식 한 번에 지출하는 비용이 클 수는 있지만 평생 한 번 쓸까 말까 한 제도이다 보니 임직원이 가장 만족할 수 있는 복지를 주고자 도입했습니다."
        />
        <B2BReviewCard
          num={2}
          companyName="서울농수산식품공사"
          serviceDescription="농수산 관리 지방공기업"
          name=""
          title="결국 고이의 제휴 혜택이 더 커서 최종적으로 고이를 선택했습니다."
          description="조사 지원 체계를 생각해본 적 없었는데 복리후생 제안 메일을 계기로 여러 회사를 비교해 보았습니다. 1년에 몇 번 없는 일이다 보니 비용적 부담이 크지는 않지만 이왕이면 합리적인 비용으로 서비스를 제공하는 업체를 찾으려고 했고, 기업 상조로 유명한 회사 서비스랑 함께 비교했습니다. 결국 고이의 제휴 혜택이 더 커서 최종적으로 고이를 선택했습니다."
        />
        <B2BReviewCard
          num={3}
          companyName="카카오벤처스"
          serviceDescription="스타트업 전문 벤처캐피탈"
          name="관리이사 김정록"
          title="원래 이용하던 상조 복지를 바꿀만큼 매력적이었습니다."
          description="스타트업의 서비스는 대표와 많은 부분이 닮아 있다고 생각합니다. 고이 대표님의 창업 스토리와 비전을 잘 이해하고 있었기 때문에 고이가 처음 B2B 서비스를 제안했을 때 믿고 선택할 수 있었습니다. 지난 달 직원의 조부모상이 있어 고이를 이용했는데, 장례지도사님은 물론 이모님들까지 모두 친절하고 정성스럽게 장례를 맡아주고 계셔서 안심이 되었습니다. 장례 복지몰 만족도가 특히 높았던 것 같고, 장례식장, 장지 소개 같은 부분도 합리적인 비용으로 잘 진행해주었습니다. 원래 이용하던 상조 복지를 바꿀만큼 매력적인 서비스이니 고민하고 있는 대표님이 있다면 강력히 추천드립니다."
        />
        <B2BReviewCard
          num={4}
          companyName="온더룩"
          serviceDescription="No.1 패션 검색 서비스"
          name="인사팀장 이태윤"
          title="고이를 통해 장례를 치른 임직원의 추천으로 도입하게 되었습니다."
          description="투자 유치 이후 팀원 수가 빠르게 늘어나면서 인사, 총무, 회계 같은 백오피스 업무가 급증했습니다. 경조사도 그 중에 하나였고, 합리적인 비용에 믿을만한 업체를 찾았습니다. 그런던 차에 한 팀원이 작년에 고이에서 장례를 치루었는데, 장례 준비 과정부터 장례가 끝난 다음까지도 너무 편하고 좋았다고 말해주었습니다. 덕분에 어머님을 잘 보낸 것 같다면서요."
        />
      </Slide>
    </VStack>
  );
}

const Slide = styled(SlideBase)`
  padding: 10px 0px;
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    padding: 0 8px;
  }
  .slick-list {
    margin: 0 -8px;
  }
`;
