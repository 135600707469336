import { useRecathModal } from "@/hooks/useRecathModal";
import { Box } from "@chakra-ui/react";
import { Button, colors, responsive, VStack } from "goi_common";
import React from "react";

export default function B2BServiceDownloadSection() {
  const { openServiceDownloadModal } = useRecathModal();
  return (
    <VStack
      width="100%"
      alignItems="center"
      background={colors.orange650}
      css={responsive({
        padding: { base: "64px 24px 72px", lg: "99px 0 88px" },
      })}
    >
      <VStack
        maxWidth={`${1200}px`}
        width="100%"
        gap="20px"
        css={responsive({
          alignItems: { base: "center", lg: "flex-start" },
        })}
      >
        <Box
          className="white head_24_b lg_head_42_b"
          css={responsive({
            textAlign: { base: "center", lg: "left" },
          })}
        >
          고이 BIZ 임직원 조사서비스 <br className="lg_over_hidden" />
          소개서를 받아보세요
        </Box>
        <Box
          className="white subtitle_18_r lg_head_28_m"
          css={responsive({
            textAlign: { base: "center", lg: "left" },
          })}
        >
          기업 부담은 낮추고, 임직원 만족도는 <br className="lg_over_hidden" />
          높이는 임직원 조사 서비스
          <br />
          지금 아래 버튼을 클릭해 서비스 소개서를 받으세요
        </Box>
        <Button
          css={responsive({
            width: "fit-content",
            padding: { base: "14px 22px", lg: "20px 30px" },
            margin: { base: "10px 0", lg: "40px 0" },
            background: colors.brown800,
            color: colors.white,
            borderRadius: "10px",
          })}
          className="subtitle_16_b lg_head_24_b"
          onClick={openServiceDownloadModal}
        >
          서비스 소개서 다운로드
        </Button>
      </VStack>
    </VStack>
  );
}
