import { useRecathModal } from "@/hooks/useRecathModal";
import {
  Box,
  Collapse,
  Flex,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  colors,
  HStack,
  GoiLogo,
  NextLink,
  customEvent,
  useResponsive,
  responsive,
  VStack,
  ColorIcon,
  Button,
} from "goi_common";
import { useRouter } from "next/router";

export default function Header() {
  const { isBiggerThanLg } = useResponsive();
  const { openModal } = useRecathModal();

  return (
    <>
      <VStack
        css={responsive({
          height: {
            base: 66,
            lg: 66,
          },
          borderBottom: `1px solid ${colors.gray100}`,
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          position: "fixed",
          width: "100%",
          top: "0",
          backgroundColor: colors.white,
          transition: "background-color 0.1s linear",
          zIndex: "1000",
          userSelect: "none",
        })}
      >
        <HStack
          alignItems="center"
          justifyContent="space-between"
          gap={isBiggerThanLg ? "36px" : "12px"}
          maxWidth="1200px"
          width={"100%"}
        >
          <HStack gap="12px" alignItems="center">
            <NextLink
              id="goi-logo"
              href={"/"}
              css={{ width: "fit-content" }}
              onClick={() => {
                customEvent({
                  newGtm: {
                    path: "gnb",
                    trigger_and_target: "logo-uv_home",
                  },
                });
              }}
            >
              <HStack
                gap={isBiggerThanLg ? "12px" : "8px"}
                margin={isBiggerThanLg ? 0 : "0 14px"}
                alignItems="center"
              >
                <GoiLogo color={"black"} size={isBiggerThanLg ? 30 : 24} />
                <div className={"subtitle_18_b md_subtitle_21_b black"}>
                  BIZ
                </div>
              </HStack>
            </NextLink>

            <Box
              css={responsive({
                fontSize: { base: 14, lg: 16 },
                fontWeight: 600,
                color: colors.gray500,
                marginLeft: { base: 0, lg: "24px" },
              })}
            >
              <HStack alignItems="center" gap="2px">
                <ServicePopover serviceType="서비스 소개" />
              </HStack>
            </Box>
            <NextLink
              href={"/service/"}
              css={responsive({
                fontSize: { base: 14, lg: 16 },
                fontWeight: 600,
                color: colors.gray500,
                marginLeft: { base: 0, lg: "24px" },
              })}
              onClick={() => {
                customEvent({
                  newGtm: { path: "gnb", trigger_and_target: "uv_service" },
                });
              }}
            >
              가격 안내
            </NextLink>
          </HStack>
          <Button
            css={responsive({
              borderRadius: "30px",
              width: "fit-content",
              padding: "8px 12px",
              backgroundColor: colors.orange650,
              color: colors.white,
            })}
          >
            <HStack gap="3px" alignItems="center" onClick={openModal}>
              <Box lineHeight="10px" fontSize={14} fontWeight={600}>
                도입 문의
              </Box>
              <ColorIcon name="headphone-mono" size={16} color={colors.white} />
            </HStack>
          </Button>
        </HStack>
      </VStack>
    </>
  );
}

function ServicePopover({
  serviceType,
  currentServiceType,
}: {
  serviceType: string;
  currentServiceType?: string | null;
}) {
  // onClick={() => handleServiceTabChange(SERVICE_PACKAGE_TYPE)}
  const { isOpen, onOpen, onClose } = useDisclosure();
  const router = useRouter();

  return (
    <VStack width="fit-content">
      <Box
        onClick={() => {
          customEvent({
            newGtm: { path: "gnb", trigger_and_target: "tab_all" },
          });
          if (isOpen) {
            onClose();
          } else {
            onOpen();
          }
        }}
        display="flex"
        alignItems="center"
      >
        {serviceType}
        <IconButton
          size="xs"
          padding="0 2px"
          variant="unstyled"
          aria-label="dropdown"
          icon={
            <ColorIcon
              name="arrow_right_small"
              css={{ transform: isOpen ? "rotate(270deg)" : "rotate(90deg)" }}
              color={
                currentServiceType === serviceType
                  ? colors.gray800
                  : colors.gray500
              }
              size={18}
            />
          }
        />
      </Box>
      <Collapse
        in={isOpen}
        animateOpacity
        style={{
          position: "fixed",
          marginTop: "40px",
          marginLeft: "-20px",
          borderRadius: "0 0 4px 4px",
        }}
      >
        <VStack
          background={colors.white}
          width="120px"
          style={{
            textAlign: "center",
          }}
          justifyContent="center"
        >
          <Flex
            height="48px"
            alignItems="center"
            justifyContent="center"
            onClick={() => {
              customEvent({
                newGtm: { path: "gnb", trigger_and_target: "tab_funeral" },
              });
              router
                .push({ pathname: "/", hash: "home-funeral" })
                .then((res) => {
                  onClose();
                });
            }}
          >
            장례서비스
          </Flex>

          <Flex
            height="48px"
            alignItems="center"
            justifyContent="center"
            onClick={() => {
              customEvent({
                newGtm: { path: "gnb", trigger_and_target: "tab_service" },
              });
              router.push({ pathname: "/disposal/" }).then((res) => {
                onClose();
              });
            }}
          >
            상조용품
          </Flex>

          <Flex
            height="48px"
            alignItems="center"
            justifyContent="center"
            onClick={() => {
              customEvent({
                newGtm: { path: "gnb", trigger_and_target: "tab_flower" },
              });
              router.push({ pathname: "/flower/" }).then((res) => {
                onClose();
              });
            }}
          >
            근조화환
          </Flex>
        </VStack>
      </Collapse>
    </VStack>
  );
}
