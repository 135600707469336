import { useRecathModal } from "@/hooks/useRecathModal";
import { Box } from "@chakra-ui/react";
import {
  ColorIcon,
  colors,
  HStack,
  LargeButton,
  LARGEL_BUTTON_TYPE,
  responsive,
} from "goi_common";
import React from "react";

export default function ConsultButton({ text }: { text?: string }) {
  const { openModal } = useRecathModal();
  return (
    <LargeButton
      buttonType={LARGEL_BUTTON_TYPE.ORANGE}
      className="subtitle_21_b lg_head_24_b"
      css={responsive({
        borderRadius: "10px",
        width: "fit-content",
        padding: { base: "20px 40px", lg: "30px 40px" },
        margin: "30px 0",
      })}
      onClick={() => {
        openModal();
      }}
    >
      <HStack gap="6px" alignItems="center">
        <Box>{text || "도입 문의"}</Box>
        <ColorIcon name="headphone-mono" size={22} color={colors.white} />
      </HStack>
    </LargeButton>
  );
}
